import { CarouselSingle } from '@dx-ui/osc-carousel';

import { getSingleCarouselImages } from '../../helpers/carousel';
import { ImageStrip } from '../image-strip';

import type { GetHotelHomePageQuery } from '../../generated/types';

export type InspiredHeroProps = {
  images: NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['images']>['carousel'];
};

const getCarouselImages = (carouselImages: NonNullable<InspiredHeroProps['images']>) =>
  carouselImages.map((carouselImage) => ({
    altText: carouselImage?.altText || '',
    desktopImageUrl: carouselImage?.variants.find((variant) => variant.size === 'md')?.url || '',
    mobileImageUrl: carouselImage?.variants.find((variant) => variant.size === 'sm')?.url || '',
  }));

const InspiredHero = ({ images }: InspiredHeroProps) => (
  <>
    <div className="block lg:hidden">
      <CarouselSingle images={getSingleCarouselImages(images)} />
    </div>
    <div className="hidden lg:block">
      <ImageStrip images={getCarouselImages(images)} />
    </div>
  </>
);

export default InspiredHero;
