import type { CarouselImages } from '@dx-ui/osc-carousel';
import type {
  GetHotelRoomsQuery,
  GetHotelHomePageQuery,
  GetHotelEventsPageQuery,
} from '../generated/types';

export const getSingleCarouselImages = (
  carouselImages:
    | NonNullable<GetHotelRoomsQuery['hotel']>['roomTypes'][number]['carousel']
    | NonNullable<NonNullable<GetHotelHomePageQuery['hotel']>['images']>['carousel']
): CarouselImages[] =>
  carouselImages.map((carouselImage) => ({
    alt: carouselImage.altText || '',
    url: carouselImage.variants.find((variant) => variant.size === 'sm')?.url || '',
  }));

export const getEventsCarouselImages = (
  carouselImages: NonNullable<
    NonNullable<GetHotelEventsPageQuery['hotel']>['images']
  >['meetingGallery']
): CarouselImages[] =>
  carouselImages.map((carouselImage) => ({
    alt: carouselImage.image?.altText || '',
    url: carouselImage.image?.variants.find((variant) => variant.size === 'md')?.url || '',
  }));
