import cx from 'classnames';
import { useTranslation } from 'next-i18next';

export const CarouselNoImage = ({
  className,
  isFullSize = false,
}: {
  className?: string;
  isFullSize?: boolean;
}) => {
  const { t } = useTranslation('osc-carousel');

  return (
    <div
      className={cx(
        '@container/no-image bg-bg-alt flex items-center justify-center',
        { 'h-full w-full': isFullSize, 'aspect-[3/2]': !isFullSize },
        className
      )}
    >
      <span className="@[256px]/no-image:text-2xl @[150px]/no-image:text-base text-text-alt text-xs">
        {t('noPhotoAvailable')}
      </span>
    </div>
  );
};
